.standalone {
  @apply bg-white;
  --standalone-border-color: rgba(black, .1);
  --standalone-text-color: inherit;

  &--inverse {
    @apply bg-black;
    --standalone-border-color: rgba(white, .1);
    --standalone-text-color: white;
    text-shadow: .025em .025em 0 rgba(black, .35);

    .navbar-global {
      border: none !important;
    }
  }

  background-position: center;
  background-size: cover;
  color: var(--standalone-text-color);

  @supports not(mix-blend-mode: overlay) {
    background-image: none !important;
  }

  .navbar-global {
    background: transparent !important;

    .navbar-brand {
      color: var(--standalone-text-color);
    }
  }

  .navbar-title {
    position: relative;

    &:before {
      display: block;
      content: '';
      width: 1px;
      background: var(--standalone-border-color);
      position: absolute;
      left: 0;
      top: -.5rem;
      bottom: -.5rem;
    }
  }

  main {
    display: flex;
    min-height: calc(100vh - 5rem);
  }

  &-progress {
    position: absolute;
    width: 100%;
    height: 10px;
    border-radius: 0;
  }

  .x {
    position: fixed;
    top: 1.25rem;
    right: 1.25rem;
    z-index: 1032;
  }
}
