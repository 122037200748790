@mixin card {
  h2, h3 {
    font-size: inherit;
    margin: 0;
    line-height: inherit;

    a {
      display: inline-block;
    }

    .special {
      position: relative;
      top: -.075rem;
    }
  }

  .community-logo {
    @apply absolute bottom-0 z-10;
    right: .25em;
    border: 2px solid white;
    margin-bottom: -2em;

    @include media-breakpoint-up(sm) {
      right: .5em;
      margin-bottom: -.75em;
    }

    @include media-breakpoint-up(lg) {
      right: 1em;
      margin-bottom: -2em;
    }
  }

  &__images {
    background-color: #232121;
    background-image: linear-gradient(#232121, #6f6b6b);
    background-size: 100%;
    position: relative;
    padding-top: 66.667%;
    color: white;
    font-weight: 600;
  }

  &-discount {
    color: #4b0909;
    pointer-events: none;
  }

  &-rate {
    background: rgba(black, .8);
    display: inline-block;
    padding: .5em;

    &:empty {
      display: none;
    }
  }

  @include card-variant(&, 'fixed', darken(#d2f3f7, 20%));
  @include card-variant(&, 'future', darken(#fdf6e0, 20%));
  @include card-variant(&, 'present', darken(#e8ffce, 20%));
  @include card-variant(&, 'past', darken(#fff, 20%));
}

@mixin card-variant($root, $name, $color) {
  &--#{$name} {
    #{$root}-time-reference {
      background: $color;
      color: darken($color, 60%);
    }
  }
}

.community {
  @include card;
}

.trip {
  @include card;
}
