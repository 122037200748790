.modal {
  min-width: 320px;

  .close {
    position: absolute;
    top: 1.6rem;
    right: 1.6rem;
    width: 3.75rem;
    height: 3.75rem;
    font-size: 3rem;
    padding: 0;
    text-align: center;
    overflow: hidden;
  }
}

.modal-dialog {
  @include media-breakpoint-down(xs) {
    margin: 0;
  }
}

.modal-content {
  border: 0;

  @include media-breakpoint-down(xs) {
    min-height: 100vh;
    border-radius: 0;

    @supports (-webkit-touch-callout: none) {
      min-height: -webkit-fill-available;
    }
  }
}

.modal-full {
  position: absolute;
  top: 0;
  min-width: 100%;
  margin: 0;

  .modal-content {
    min-height: 100vh;
    border: 0;
    border-radius: 0;

    @supports (-webkit-touch-callout: none) {
      min-height: -webkit-fill-available;
    }
  }

  .close {
    top: .75rem;
    right: .75rem;
    z-index: 1;
  }
}

.modal-lg {
  .close {
    top: 2rem;
    right: 2rem;
  }
}
