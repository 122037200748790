$offset: 125;
$duration: 1.5s;

.spinner {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1050;

  svg {
    display: block;
    width: 4rem;
    height: 4rem;
    position: fixed;
    top: 50%;
    left: 50%;
    margin: -2rem 0 0 -2rem;
    animation: rotator $duration linear infinite;

    circle {
      fill: none;
      stroke-width: 6px;

      &:first-child {
        stroke: #f5f5f5;
      }

      &:last-child {
        stroke: #0092da;
        stroke-dasharray: $offset;
        stroke-dashoffset: 0;
        stroke-linecap: square;
        animation: dash $duration ease-in-out infinite;
        transform-origin: center;
      }
    }
  }
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: $offset;
  }
  50% {
    stroke-dashoffset: $offset/4;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: $offset;
    transform: rotate(450deg);
  }
}
