.navbar, .nav {
  background-color: white;
}

.navbar-global {
  @apply static h-20;
  position: static;
  min-width: 320px;
  border-bottom: 1px solid #ededed;
  z-index: 1031;

  .container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }

  .navbar-brand {
    position: relative;
    display: block;
    width: 3.75rem;
    height: 3rem;
    padding-right: 1.25rem;
    flex-shrink: 0;

    svg {
      width: 100%;
      height: 100%;
    }

    &:after {
      display: block;
      content: '⌄';
      width: 1rem;
      height: 1.5rem;
      position: absolute;
      top: .75rem;
      right: 0;
      font-size: 1.25rem;
      line-height: 1.25;
      color: $gray-600;
      text-align: center;
      transform: scaleX(1.5);
    }
  }

  .navbar-nav {
    flex-direction: row;

    .nav-link {
      padding-right: .5rem;
      padding-left: .5rem;
    }
  }

  .navbar-nav {
    .nav-link, .dropdown-link {
      color: rgba(black, .6);

      &:focus, &:hover {
        color: rgba(black, .7);
      }
    }
  }

  .navbar-search {
    position: relative;
    width: 100%;

    .nav-item {
      width: 100%;

      .form-control {
        height: auto;
        padding: .75rem .75rem .75rem 2.725rem;
        font-weight: 600;
        line-height: normal;

        &:focus::placeholder {
          visibility: hidden;
        }
      }

      .search-btn, .reset-btn {
        position: absolute;
        background: none;
        border: none;
        top: 0;
        width: 2.75rem;
        height: 100%;
        padding: 0;
        font-size: 1.125rem;
        color: $input-placeholder-color;
        cursor: pointer;
        line-height: normal;
        outline: 0;
        text-align: center;

        &:focus-visible {
          box-shadow: $input-focus-box-shadow;
        }
      }

      .search-btn {
        left: 0;
        padding-left: .5rem;
      }

      .reset-btn {
        right: 0;
      }
    }
  }

  .navbar-user {
    white-space: nowrap;

    &-dropdown {
      .nav-link {
        display: block;
        width: 38px;
        height: 38px;
        padding: 0;
      }
    }

    span.dropdown-item {
      &:hover {
        background: inherit;
      }
    }
  }
}

.navbar-dropdown {
  width: 100vw;
  min-width: 320px;
  margin: .875rem -1rem;
  border-color: #ededed;
  border-radius: 0;
  border-width: 1px 0;
  box-shadow: 0 6px 6px rgba(black, .25);
  overflow-y: auto;

  @include media-breakpoint-up(sm) {
    width: auto;
    max-width: 28rem;
    max-height: calc(100vh - 5rem);
    margin: .125rem 0 0;
    border-width: 1px;
    border-radius: .25rem;
  }

  .dropdown-icon {
    display: inline-flex;
    width: 1.25rem;
    height: 1.25rem;
    margin: -.25rem .05rem 0 0;
    justify-content: center;
  }

  .dropdown-item {
    &:focus, &:hover {
      background: $primary;
      color: white;
    }

    &:active {
      background: darken($primary, 7.5%);
      color: white;

      @media (hover: none) {
        background: $primary;
      }
    }

    .badge {
      position: relative;
      top: -.25em;
      margin-left: .25em;
      padding: .5em .75em .4em;
      font-size: .5em;
      line-height: inherit;
    }
  }
}

.navbar-local {
  @apply bg-slate-50;
  overflow-x: scroll;
  border-bottom: 1px solid rgba(black, .125);
  margin: 0 -$grid-gutter-width-base;
  flex-wrap: nowrap;
  white-space: nowrap;
  backface-visibility: hidden;

  @include media-breakpoint-up(sm) {
    margin: 0;
  }

  .nav-item {
    margin: 0;

    .nav-link {
      @apply bg-transparent border-b-2 border-slate-50 px-4 py-3 text-slate ring-inset;

      &.active {
        color: lighten($primary, 5%);
        border-bottom-color: lighten($primary, 5%);
      }
    }
  }
}
