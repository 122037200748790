.duration-slider {
  margin: 1rem 0;
  padding: 0 .75rem;

  .form-group {
    margin: 0;

    .form-control {
      padding: 0;
    }
  }

  .noUi {
    &-draggable, &-handle, &-horizontal {
      cursor: pointer;
    }

    &-horizontal {
      height: 6px;
      cursor: pointer;

      .noUi-handle {
        @apply w-7 h-7 focus-visible:ring-2;
        left: -14px;
        top: -12px;
        border-color: $primary;
        border-radius: 50%;
        border-width: 3px;

        &:before, &:after {
          display: none;
        }
      }
    }

    &-connect {
      background: $primary;
    }
  }
}

.price-slider {
  margin: 1rem 0;
  padding: 0 .75rem;

  .form-group {
    margin: 0;

    .form-control {
      padding: 0;
    }
  }

  .histogram {
    height: 4rem;
    font-size: 0;
  }

  .histogram-bar {
    @apply inline-block bg-slate-300;
  }

  .noUi {
    &-draggable, &-handle, &-horizontal {
      cursor: pointer;
    }

    &-horizontal {
      height: 6px;
      cursor: pointer;

      .noUi-handle {
        @apply w-7 h-7 focus-visible:ring-2;
        left: -14px;
        top: -12px;
        border-color: $primary;
        border-radius: 50%;
        border-width: 3px;

        &:before, &:after {
          display: none;
        }
      }
    }

    &-connect {
      background: $primary;
    }
  }
}

