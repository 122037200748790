.community-logo {
  @apply bg-white inline-block align-middle overflow-hidden rounded-full;

  &-xs {
    width: 1.5em;
    height: 1.5em;
  }

  &-sm {
    width: 3.125em;
    height: 3.125em;

    .jumbotron & {
      width: 3.125rem;
      height: 3.125rem;
    }
  }

  &-md {
    width: 4.5em;
    height: 4.5em;

    .jumbotron & {
      width: 4.5rem;
      height: 4.5rem;
    }
  }

  &-lg {
    width: 6.25em;
    height: 6.25em;

    .jumbotron & {
      width: 6.25rem;
      height: 6.25rem;
    }
  }

  img {
    @apply max-w-full max-h-full object-cover;
  }
}
