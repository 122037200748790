.CodeMirror {
  &, &-scroll {
    min-height: 12rem;
  }

  &-fullscreen {
    z-index: 8000;
  }
}

.editor-toolbar.fullscreen {
  z-index: 8000;
}

.markdown {
  blockquote {
    @extend .blockquote;

    p:last-child {
      margin-bottom: 0;
    }
  }
}
