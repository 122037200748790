@import "variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @import "~bootstrap/scss/root";
  @import "reset";

  html, body, #app {
    @apply h-full m-0 p-0 focus:ring-0;
    min-width: 320px;
  }

  :focus {
    @apply outline-none;
  }

  :focus-visible {
    @apply ring-2 ring-sky-600/75;
  }

  // ::-webkit-scrollbar {
  //   @apply bg-slate-200 w-2 h-2;
  // }

  // ::-webkit-scrollbar-thumb {
  //   @apply bg-slate-400 rounded-full;
  // }

  // ::-webkit-scrollbar-corner {
  //   @apply bg-slate-200;
  // }

  a {
    cursor: pointer;
  }

  button {
    box-sizing: border-box;
    cursor: pointer;
    text-align: unset;
  }

  input, optgroup, select, textarea {
    @apply ring-sky-600/75;
    font-family: inherit;
    color: #999;
  }

  label {
    @apply ring-sky-600/75;
  }

  legend {
    font-weight: 600;
  }

  textarea {
    min-height: 4rem;
  }

  [v-cloak] {
    display: none;
  }
}

@layer components {
  @import "modules/bootstrap";
  @import "modules/fontawesome";
  @import "~nouislider/distribute/nouislider";
  @import "~nprogress/nprogress";
  @import "~simplemde/dist/simplemde.min";
  @import "~sweetalert2/src/sweetalert2";

  @import "modules/alerts";
  @import "modules/buttons";
  @import "modules/auth";
  @import "modules/autocomplete";
  @import "modules/avatars";
  @import "modules/cards";
  @import "modules/communities";
  @import "modules/files";
  @import "modules/jumbotron";
  @import "modules/markdown";
  @import "modules/markers";
  @import "modules/modals";
  @import "modules/navbars";
  @import "modules/participants";
  @import "modules/programs";
  @import "modules/ratings";
  @import "modules/review";
  @import "modules/search";
  @import "modules/slider";
  @import "modules/spinner";
  @import "modules/standalone";
  @import "modules/starability";
  @import "modules/tablesorter";
  @import "modules/trips";

  .avatar {
    display: inline-block;
    background-color: #f5f5f5;
    border: 2px solid white;
    border-radius: 100%;
    position: relative;
    width: 2.5rem;
    height: 2.5rem;
    background-size: cover;
    margin-right: -0.625rem;
  }

  .bubble {
    border: 0;
    width: 320px;
    padding-bottom: 0;
    overflow: hidden;

    &, *, *:before, *:after {
      box-sizing: border-box !important;
    }
  }

  .collapse.show + .btn {
    display: none;
  }

  .logo {
    display: inline;
    fill: #003457;
    color: #0083d7;

    &-inline {
      width: 1.5em;
      height: 1.5em;
      margin-top: -0.2em;
      vertical-align: middle;
    }
  }

  .gm-style {
    font: inherit !important;
  }

  .map {
    background: #9ecaff;
    height: 100%;

    *, *:before, *:after {
      box-sizing: content-box;
    }
  }

  .plans {
    background-color: #f5f5f5;
    border-radius: 5px;
    box-shadow: inset 1px 1px 2px rgba(black, 0.1);
  }

  .plan {
    position: relative;
    background: #f5f5f5;
    padding: 0;

    label {
      border: 1px solid #f5f5f5;
      margin: 0;
      padding: 4px 0;
      color: #888;
      cursor: pointer;
    }

    input {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
      cursor: pointer;
    }

    input:checked ~ label {
      background: white;
      color: #000;
      border-radius: 0.25rem;
      box-shadow: 1px 1px 2px rgba(black, 0.1);
      border-color: #dddddd;
      width: 105%;
      margin-left: -2.5%;
      margin-top: -1px;
      margin-bottom: -1px;
      padding: 5px 0;
    }

    &.best {
      label:before {
        position: absolute;
        content: "Best Value";
        font-size: 0.75em;
        font-weight: bold;
        color: #7b7b7b;
        top: 2em;
        right: 0em;
        margin-top: 2em;
        margin-right: 0.9em;
      }

      label:after {
        position: absolute;
        content: "⭐️";
        font-size: 2em;
        top: 0;
        right: 0;
        margin-top: 0.25em;
        margin-right: 0.725em;
      }
    }
  }

  .image-form-control {
    position: relative;
    width: 160px;
    height: 160px;
    cursor: pointer;
    overflow: hidden;

    label, img, input {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      cursor: pointer;
    }

    label {
      display: flex;
      align-items: center;
      text-align: center;
      opacity: 0.75;
    }

    input {
      opacity: 0;
    }
  }

  .table {
    td, th {
      @apply align-middle border-slate-200;
    }

    th {
      white-space: nowrap;

      label {
        margin-bottom: 0;
      }

      .fa {
        position: relative;
        top: -0.25rem;
        font-size: 0.8rem;
        padding: 0.2rem;
      }
    }

    &-scroll {
      overflow-x: auto;
    }
  }

  .table-striped tbody tr:nth-of-type(odd) {
    @apply bg-slate-100;
  }

  .image-preview {
    display: block;
    cursor: pointer;

    &, img {
      width: 100%;
      max-width: 260px;
    }
  }

  .list-pipes {
    position: relative;
    overflow: hidden;

    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-left: -1px;

      li {
        flex-grow: 1;
        flex-basis: auto;
        margin: 0.25em 0;
        padding: 0 0.25em;
        text-align: center;
        border-left: 1px solid #ccc;
        background-color: #fff;
      }
    }
  }

  .progress {
    @apply bg-slate-200;
  }

  #djDebug {
    @apply hidden sm:block;
  }
}

@layer utilities {
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .optimize-legibility {
    text-rendering: optimizelegibility;
  }

  .rotate {
    animation: spinner 1s linear infinite;
  }

  .text-body {
    color: $body-color;
  }

  .text-muted {
    @apply text-slate-500;
  }

  .text-shadow {
    text-shadow: rgba(0, 0, 0, 0.25) 2px 2px 1px;
  }

  .no-text-shadow {
    text-shadow: none;
  }

  .invisible {
    visibility: hidden;
  }

  .visible {
    visibility: visible;
  }
}
