$font-family-sans-serif: 'Nunito Sans', "Noto Color Emoji", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;

$font-weight-normal: 400;
$font-weight-bold: 600;

$headings-font-weight: $font-weight-bold;

$blue: #0092da;
$green: #4caf50;

$grid-breakpoints: (
  xs: 0,
  sm: 640px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  xxl: 1536px
);
$grid-columns: 12;
$grid-gutter-width-base: 15px;
$grid-gutter-widths: (
  xs: 15px,
  sm: 15px,
  md: 20px,
  lg: 30px,
  xl: 30px,
);

$input-color-placeholder: #999;
$input-border-color: #efefef;
$input-border-focus: #efefef;

$navbar-height: 5rem;

$navbar-padding-x: 1rem;
$navbar-padding-y: 1rem;

$secondary: #efefef;

$yellow: #f0ad4e;
