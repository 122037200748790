.facilitator-avatar {
  @apply inline-block max-w-full object-cover overflow-hidden rounded-full;
  background: #eee;
  width: 128px;
  height: 128px;
  font-size: 0;

  @include media-breakpoint-up(lg) {
    width: 192px;
    height: 192px;
  }
}

.reviewer-avatar {
  @apply object-cover overflow-hidden rounded-full;
  position: absolute;
  top: 50%;
  left: 15px;
  width: 32px;
  height: 32px;
  margin-top: -16px;
  font-size: 0;
}
