a.alert {
  text-decoration: none;

  &.alert-warning {
    background: #fdf6e0;
    border-color: desaturate(rgba($warning, .25), 20%);
    &, &:focus-visible, &:hover { color: desaturate(darken($warning, 30%), 50%); }
    &:focus-visible, &:hover { background: darken(#fdf6e0, 5%); }
  }
}
