.marker {
  @apply cursor-pointer font-sans;

  --marker-color: red;
  --marker-border: #dc2626;

  &.marker--past {
    --marker-color: #71717a;
    --marker-border: #52525b;
  }

  &.marker--featured {
    --marker-color: #fbbe00;
    --marker-border: #d99a07;
  }

  &.marker--highlight {
    --marker-color: #0d9488;
    --marker-border: #0f766e;
  }

  &__content {
    background: var(--marker-color);
    border: 1px solid var(--marker-border);
    position: relative;
    border-radius: 4px;
    min-width: 1rem;
    margin-bottom: .5rem;
    padding: .1rem .4rem;
    font-size: .8rem;
    color: white;
    font-weight: 600;
    text-align: center;
    text-shadow: 1px 1px 1px rgba(black, .25);

    &:before, &:after {
      display: block;
      content: '';
      position: absolute;
      bottom: -.5rem;
      left: 50%;
      border-left: .5rem solid transparent;
      border-right: .5rem solid transparent;
      border-top: .5rem solid var(--marker-color);
      width: 0;
      height: 0;
      margin-left: -.5rem;
    }

    &:before {
      border-top: .5rem solid var(--marker-border);
      margin-bottom: -1px;
    }
  }

  &-infobubble {
    font-size: 1rem;

    &-image {
      display: block;

      img {
        display: block;
        max-width: 100%;
      }
    }

    &-text {
      display: block;
      color: inherit;
      text-decoration: none;
      padding: .5rem 0;

      strong {
        display: block;
      }
    }
  }
}
