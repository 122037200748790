.rating {
  display: inline-block;
  line-height: 1;

  .text {
    display: inline-block;
    color: inherit;

    .community & {
      top: -.1rem;
      position: relative;
    }

    .community &, .review &, .trip & {
      @apply text-slate-500;
    }
  }
}

.stars {
  display: inline-block;
  position: relative;
  top: -.1em;
  line-height: .8;
  vertical-align: middle;
}

.star {
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: middle;
  fill: #989898;

  &-lg {
    width: 2em;
    height: 2em;
  }

  &--filled {
    fill: #fbbe00;

    &--half {
      fill: url(#star-half-gradient);
    }
  }
}
