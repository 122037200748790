.auth {
  &:before {
    background-color: darken($primary, 10%);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block; content: '';
    width: 100%; height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  main {
    color: white;
    padding-top: 0;
    text-shadow: 0 0 1px rgba(darken($primary, 40%), .5),
           0 0 2rem rgba(darken($primary, 40%), .75);

    h1 {
      font-size: 3rem;
      font-weight: normal;

      @include media-breakpoint-up(md) {
        font-size: 4rem;
      }
    }

    a {
      color: inherit;
      text-decoration: none;

    }

    .btn {
      width: 16.5rem;
      box-shadow: 0 0 2rem rgba(darken($primary, 40%), .25);
      text-shadow: 0 0 1px rgba(darken($primary, 40%), .25),
             0 0 1rem rgba(darken($primary, 40%), .25);
    }
  }
}
