








button {
  cursor: pointer;
}

.nw {
  white-space: nowrap;
}
