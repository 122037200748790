$star-count: 5;
$star-size: 30px;
$images: '../images';

@mixin starability($icons: 'icons') {
  display: block;
  position: relative;
  width: $star-count * $star-size;
  padding: 0;
  border: none;

  > {
    input {
      &, &:first-of-type + label {
        position: absolute;
        margin-left: -1000%;
        opacity: 0;
      }

      &:checked, &:focus {
        ~ label { background-position: 0 0; }
        + label { background-position: 0 (-$star-size); }
      }

      &:first-of-type:checked:focus {
        ~ label { outline: 1px dotted #999; }
      }

      &:focus {
        + label { outline: 1px dotted #999; }
      }
    }

    label {
      background-image: url('#{$images}/#{$icons}.png');
      background-position: 0 (-$star-size);
      background-repeat: no-repeat;
      position: relative;
      display: inline-block;
      float: left;
      width: $star-size;
      height: $star-size;
      margin: 0;
      color: transparent;
      cursor: pointer;

      @media screen and (min-resolution: 192dpi) {
        background-image: url('#{$images}/#{$icons}@2x.png');
        background-size: $star-size auto;
      }
    }
  }

  &:hover {
    > {
      label { &, &:before { background-position: 0 (-$star-size); } }

      input {
        ~ label { &, &:before { background-position: 0 (-$star-size); } }
        + label { &, &:before { background-position: 0 (-$star-size); } }

        &:hover {
          ~ label { &, &:before { background-position: 0 0; } }
          + label { &, &:before { background-position: 0 (-$star-size); } }
        }
      }
    }
  }
}

@mixin starability-animation($icons: 'icons') {
  display: none;
  position: absolute;
  content: ' ';
  width: $star-size;
  height: $star-size;
  background-image: url('#{$images}/#{$icons}.png');
  background-repeat: no-repeat;

  @media screen and (min-resolution: 192dpi) {
    background-image: url('#{$images}/#{$icons}@2x.png');
    background-size: $star-size auto;
  }
}

@keyframes throb {
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}

.starability {
  @include starability;

  > label:before {
    @include starability-animation;
    bottom: 0;
  }

  > input:checked + label:before {
    background-position: 0 (-$star-size);
    display: block;
    animation-duration: .5s;
    animation-name: throb;
    animation-fill-mode: forwards;
  }

  &-details {
    max-height: 0;
    padding-top: 1rem;
    font-size: 1rem;
    opacity: 0;
    overflow: hidden;
    transform: translateY(-.5rem);
    transition: opacity .5s, max-height .5s, transform .5s, visibility 0s .5s;
    visibility: hidden;

    &.active {
      max-height: 20rem;
      opacity: 1;
      overflow: visible;
      transform: translateY(0);
      transition-delay: 0s;
      visibility: visible;
    }

    textarea {
      width: 100%;
      height: 3rem;
      border: 1px solid rgba(0, 0, 0, .1);
      max-height: 16rem;
      margin: .5rem 0;
      padding: .5rem;
    }
  }
}
