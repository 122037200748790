.easy-autocomplete {
  @apply relative;

  a {
    @apply block;
  }


  .form-control {
    &:focus {
      border-color: $input-border-focus;
      box-shadow: none;
    }

    &:has(~ div > ul:not(:empty):not([style*="display: none"])) {
      @apply rounded-b-none;

      .navbar & {
        @apply rounded-b-3xl;
      }
    }
  }
}

.easy-autocomplete-container {
  @apply absolute w-full left-0 z-50;

  ul {
    @apply hidden bg-white -mt-px rounded-b overflow-hidden;

    border: 1px solid $input-border-focus;
    padding-bottom: 0;
    padding-left: 0;

    li {
      @apply block bg-white px-4 py-2 text-sm;
      border-bottom: 1px solid $input-border-focus;
      font-weight: normal;

      &:last-child {
        @apply border-b-0;
      }

      &.selected {
        background: none repeat scroll 0 0 $input-border-focus;
        cursor: pointer;

        div {
          font-weight: normal;
        }
      }

      b {
        font-weight: bold;
      }

      div {
        display: block;
        font-weight: normal;
        word-break: break-all;
      }

      img {
        border-radius: 50%;
        width: 1rem;
        height: 1rem;
        margin: -.075rem .5rem 0 0;
        overflow: hidden;
      }

      small {
        @apply inline-block w-16;
      }
    }
  }

  .navbar-global & {
    @apply w-screen left-[-5.75rem] sm:w-full sm:left-0;

    ul {
      @apply relative top-4 rounded-none sm:top-0 sm:rounded-b-3xl;

      li {
        @apply px-4 py-3;
      }
    }
  }
}
