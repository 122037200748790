.search {
  main {
    @apply h-[calc(100%-5rem)];
  }
}

.search-nav {
  box-shadow: 0 0 1rem rgba(black, .1);
  margin: 3.5rem;

  @include media-breakpoint-up(sm) {
    margin: 0 10rem;
  }
}

.filter {
  input {
    @apply block w-full h-full absolute inset-0 opacity-0 cursor-pointer;

    &:checked ~ label {
      position: relative;
      border-bottom: 4px solid $primary;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      color: $primary;
      margin-bottom: -3px;
      z-index: 1;
    }
  }

  &.radio {
    label {
      padding: .5rem;
    }
  }
}
