.amenities {
  margin-left: -7.5px;
  margin-right: -7.5px;

  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }

  @include media-breakpoint-up(lg) {
    margin-left: -10px;
    margin-right: -10px;

    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.amenity {
  height: 100%;
  color: #aaa;

  &--true {
    color: $green;
  }

  &--false {
    color: $red;
  }

  .fa:first-child {
    font-size: 2rem;
    display: block;
    text-align: center;
  }
}

.breakdown {
  &__table {
    max-height: 10.75em;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.participants {
  @include clearfix;

  img {
    display: block;
    float: left;
    width: 6.25%;
    font-size: 0;
    overflow: hidden;
  }
}

.rate {
  white-space: nowrap;
}
