.past {
  background: #eee;
  border-color: lighten(#555, 10%);
  &, &:focus-visible, &:hover { color: #555; }
  &:focus-visible, &:hover { background: darken(#eee, 5%); }
}

.present {
  background: #e8ffce;
  border-color: lighten(desaturate(darken($success, 20%), 30%), 10%);
  &, &:focus-visible, &:hover { color: desaturate(darken($success, 20%), 30%); }
  &:focus-visible, &:hover { background: darken(#e8ffce, 5%); }
}

.future {
  background: #fdf6e0;
  border-color: lighten(desaturate(darken($warning, 30%), 50%), 10%);
  &, &:focus-visible, &:hover { color: desaturate(darken($warning, 30%), 50%); }
  &:focus-visible, &:hover { background: darken(#fdf6e0, 5%); }
}

.itinerary {
  overflow-x: hidden;

  time {
    white-space: nowrap;
  }

  &__trip {
    display: block;
    margin-left: -1px;
    border-top: 1px solid;
    border-left: 1px solid;
    border-color: white;
    padding: .125rem .5rem;
    float: left;
  }
}
