.file {
  display: inline-block;
  position: relative;
  max-width: 100%;
  font-size: 0;
}

.file-input {
  font-size: 1rem;

  .js & {
    position: absolute;
    top: 0;right: 0;bottom: 0;left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
    overflow: hidden;
  }
}

.file-input + label {
  display: inline-block;
  border-radius: 4px;
  max-width: 100%;
  margin: 0;
  padding: .5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.no-js .file-input + label {
  display: none;
}

.file-input:focus + label,
.file-input.file-input--has-focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.file-input + label * {
  /* pointer-events: none; */
  /* in case of FastClick lib use */
}

.file-input + label svg {
  width: 1em;
  height: 1em;
  vertical-align: middle;
  fill: currentColor;
  margin-top: -.25em;
}

.file-input + label span {
  margin-left: .25em;
}


/* style 1 */

.file-input-1 + label {
  color: white;
  border: 1px solid $primary;
  line-height: 1.25;
  background-color: $primary;
}

.file-input-1:focus + label,
.file-input-1.file-input--has-focus + label,
.file-input-1:hover + label,
.file-input-1 + label:hover {
  background-color: darken($primary, 10%);
  border-color: darken($primary, 10%);
}

.file-input-1.file-input--has-file + label {
  color: white;
  background-color: $success;
  border-color: $success;
}

.file-input-1.file-input--has-file:focus + label,
.file-input-1.file-input--has-file.file-input--has-focus + label,
.file-input-1.file-input--has-file:hover + label,
.file-input-1.file-input--has-file + label:hover {
  background-color: darken($success, 10%);
  border-color: darken($success, 10%);
}


/* style 2 */

.file-input-2 + label {
  color: $primary;
  border: 2px solid currentColor;
  line-height: 1.25;
}

.file-input-2:focus + label,
.file-input-2.file-input--has-focus + label,
.file-input-2:hover + label,
.file-input-2 + label:hover {
  color: darken($primary, 10%);
}


/* style 3 */

.file-input-3 + label {
  color: $primary;
}

.file-input-3:focus + label,
.file-input-3.file-input--has-focus + label,
.file-input-3:hover + label,
.file-input-3 + label:hover {
  color: darken($primary, 10%);
}


/* style 4 */

.file-input-4 + label {
  color: $primary;
}

.file-input-4:focus + label,
.file-input-4.file-input--has-focus + label,
.file-input-4:hover + label,
.file-input-4 + label:hover {
  color: darken($primary, 10%);
}

.file-input-4 + label figure {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: $primary;
  display: block;
  padding: 20px;
  margin: 0 auto 10px;
}

.file-input-4:focus + label figure,
.file-input-4.file-input--has-focus + label figure,
.file-input-4:hover + label figure,
.file-input-4 + label:hover figure {
  background-color: darken($primary, 10%);
}

.file-input-4 + label svg {
  width: 100%;
  height: 100%;
  fill: #f1e5e6;
}


/* style 5 */

.file-input-5 + label {
  color: $primary;
}

.file-input-5:focus + label,
.file-input-5.file-input--has-focus + label,
.file-input-5:hover + label,
.file-input-5 + label:hover {
  color: darken($primary, 10%);
}

.file-input-5 + label figure {
  width: 100px;
  height: 135px;
  background-color: $primary;
  display: block;
  position: relative;
  padding: 30px;
  margin: 0 auto 10px;
}

.file-input-5:focus + label figure,
.file-input-5.file-input--has-focus + label figure,
.file-input-5:hover + label figure,
.file-input-5 + label:hover figure {
  background-color: darken($primary, 10%);
}

.file-input-5 + label figure::before,
.file-input-5 + label figure::after {
  width: 0;
  height: 0;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
}

.file-input-5 + label figure::before {
  border-top: 20px solid #dfc8ca;
  border-left: 20px solid transparent;
}

.file-input-5 + label figure::after {
  border-bottom: 20px solid darken($primary, 10%);
  border-right: 20px solid transparent;
}

.file-input-5:focus + label figure::after,
.file-input-5.file-input--has-focus + label figure::after,
.file-input-5:hover + label figure::after,
.file-input-5 + label:hover figure::after {
  border-bottom-color: $primary;
}

.file-input-5 + label svg {
  width: 100%;
  height: 100%;
  fill: #f1e5e6;
}


/* style 6 */

.file-input-6 + label {
  color: $primary;
}

.file-input-6 + label {
  border: 1px solid $primary;
  background-color: white;
  padding: 0;
}

.file-input-6:focus + label,
.file-input-6.file-input--has-focus + label,
.file-input-6:hover + label,
.file-input-6 + label:hover {
  border-color: darken($primary, 10%);
}

.file-input-6 + label span,
.file-input-6 + label strong {
  padding: .625rem 1.25rem;
}

.file-input-6 + label span {
  width: 200px;
  min-height: 2em;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: top;
}

.file-input-6 + label strong {
  height: 100%;
  color: white;
  background-color: $primary;
  display: inline-block;
}

.file-input-6:focus + label strong,
.file-input-6.file-input--has-focus + label strong,
.file-input-6:hover + label strong,
.file-input-6 + label:hover strong {
  background-color: darken($primary, 10%);
}

@media screen and (max-width: 50em) {
  .file-input-6 + label strong {
    display: block;
  }
}
