.people {
  background: #eee;
  min-height: 100%;

  --cols: 2;

  @include media-breakpoint-up(md) {
    --cols: 3;
  }

  @include media-breakpoint-up(lg) {
    --cols: 4;
  }

  @include media-breakpoint-up(xl) {
    --cols: 5;
  }

  @include media-breakpoint-up(sm) {
    padding-left: 320px;
  }

  &-sidebar {
    background: white;

    @include media-breakpoint-up(sm) {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      width: 320px;
      overflow-y: auto;
    }

    label {
      cursor: pointer;
    }

    .people-filters-heading {
      background: white;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 4rem;
      padding: 1rem;
      text-align: center;
      z-index: 2;
    }

    .people-filter-divider {
      position: relative;
      margin: 1rem 0;
      color: #777;
      font-size: .8rem;
      text-align: center;
      z-index: 1;

      &:before, &:after {
        position: absolute;
        display: block;
        content: '';
      }

      &:before {
        background: #eee;
        width: 100%;
        height: 1px;
        top: 50%;
        left: 0;
        right: 0;
        margin-top: -1px;
        z-index: -1;
      }

      &:after {
        background: white;
        width: 3rem;
        height: 100%;
        margin-left: -1.5rem;
        top: 0;
        bottom: 0;
        left: 50%;
        z-index: -1;
      }

      &.sr-only {
        margin: 0;
      }
    }

    label {
      margin: .25rem;
    }
  }

  &-filters {
    background: white;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 4rem 1rem 1rem;
    font-size: 0;
    overflow-y: auto;
    z-index: 2;

    @include media-breakpoint-up(sm) {
      display: block !important;
      position: static;
      padding-top: 1rem;
    }

    &.collapsing {
      &[aria-expanded="false"] {
        display: none;
      }

      transition: none;
    }

    &-toggler {
      position: fixed;
      top: 1rem;
      right: 1rem;
      color: #555;
      z-index: 3;
    }
  }

  &-list {
    display: grid;
    grid-template-columns: repeat(var(--cols), 1fr);
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

.person {
  position: relative;

  &-link {
    display: block;
    width: 100%;
    height: 100%;
    padding-bottom: 100%;
    color: white;
    cursor: pointer;
    text-shadow: 1px 1px 0 rgba(black, .35);

    &:before {
      background: rgba($primary, .25);
      display: none;
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 1rem;
      z-index: 1;
    }

    &__top {
      position: absolute;
      top: 0;
      width: 100%;
      padding: .5rem 1rem;
      text-align: right;
      z-index: 1;
    }

    &__bottom {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: .5rem;
      z-index: 1;
    }

    h2 {
      margin: 0;
      font-size: 1rem;
      line-height: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 0;
    }

    &:active, &:focus, &:hover {
      color: white;

      &:before {
        display: block;
      }

      img {
        filter: grayscale(1)
      }
    }

    &:active, &:focus {
      outline: none;

      &:before {
        background: rgba($primary, .5);
      }
    }
  }

  &-profile {
    background: white;
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    padding: 1rem;
    overflow-y: auto;
    z-index: 5;

    @include media-breakpoint-up(sm) {
      width: calc((100% - 320px) / var(--cols) * 2);
    }

    &:target {
      display: block;
    }

    img {
      width: 100%;
    }
  }
}

