.jumbotron {
  position: relative;

  .container {
    position: static;

    &-bottom {
      position: absolute;
    }
  }

  &-inverse {
    $shadow: .05em .05em 0 rgba(black, .35);

    background-color: #444;
    background-size: 100%;
    color: white;
    text-shadow: $shadow;

    &:before, &:after {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      pointer-events: none;
      z-index: 0;
    }

    &:before {
      background-position: center;
      background-size: cover;
    }

    &:after {
      background-image: linear-gradient(rgba(black, .1), rgba(black, .5));
    }

    .container {
      z-index: 1;

      a {
        color: inherit;
      }

      .btn {
        text-shadow: none;
      }

      .list-inline {
        .fa {
          width: 1.25rem;
          text-align: center;
        }
      }

      .rating {
        .stars {
          filter: drop-shadow($shadow);
        }
      }
    }
  }

  &-home {
    padding: 3rem 2rem 5rem;
    text-shadow: 0 0 20px rgba(black, .5);

    .container {
      position: relative;
      padding: 0;
      z-index: 1;

      &-bottom {
        position: absolute;
      }
    }

    @include media-breakpoint-up(md) {
      padding: 4rem 4rem 5rem;

      h1 {
        font-size: 3.2rem;
      }

      .tagline {
        font-size: 1.5rem;
      }
    }

    @include media-breakpoint-up(lg) {
      padding: 6rem 4rem 6rem;

      h1 {
        font-size: 3.6rem;
      }

      .tagline {
        font-size: 1.8rem;
      }
    }
  }

  &-bar {
    position: absolute;
    bottom: 0;
    text-align: right;
  }

  &-tag {
    display: inline-block;
    padding: .5rem 1rem;
    color: white;

    &-sm {
      font-size: .8rem;
    }

    &-translucent {
      opacity: .8;
    }
  }
}
