.btn {
  @apply inline-block px-4 py-2.5 font-semibold text-center no-text-shadow rounded-3xl text-lg leading-tight select-none whitespace-nowrap;
  @apply hover:no-underline;
}

.btn-primary {
  @apply bg-sky border border-transparent ring-sky-600/25 text-white;
  @apply hover:bg-sky-600 hover:text-white;
  @apply focus-visible:ring-4;
  @apply active:bg-sky-700 active:text-white;
}

.btn-outline-primary {
  @apply border border-sky ring-sky-600/25 text-sky;
  @apply hover:bg-sky-600 hover:border-sky-600 hover:text-white;
  @apply focus-visible:ring-4;
  @apply active:bg-sky-700 active:text-white;
}

.btn-info {
  @apply bg-cyan-500 border border-transparent ring-cyan-600/25 text-white;
  @apply hover:bg-cyan-600 hover:text-white;
  @apply focus-visible:ring-4;
  @apply active:bg-cyan-700 active:text-white;
}

.btn-outline-info {
  @apply border border-cyan-500 ring-cyan-600/25 text-cyan-500;
  @apply hover:bg-cyan-600 hover:border-cyan-600 hover:text-white;
  @apply focus-visible:ring-4;
  @apply active:bg-cyan-700 active:text-white;
}

.btn-warning {
  @apply bg-yellow-500 border border-transparent ring-yellow-600/25 text-white;
  @apply hover:bg-yellow-600 hover:text-white;
  @apply focus-visible:ring-4;
  @apply active:bg-yellow-700 active:text-white;
}

.btn-outline-warning {
  @apply border border-yellow-500 ring-yellow-600/25 text-yellow-500;
  @apply hover:bg-yellow-600 hover:border-yellow-600 hover:text-white;
  @apply focus-visible:ring-4;
  @apply active:bg-yellow-700 active:text-white;
}

.btn-danger {
  @apply bg-red-600 border border-transparent ring-red-700/25 text-white;
  @apply hover:bg-red-700 hover:text-white;
  @apply focus-visible:ring-4;
  @apply active:bg-red-800 active:text-white;
}

.btn-outline-danger {
  @apply border border-red-600 ring-red-700/25 text-red-600;
  @apply hover:bg-red-700 hover:border-red-700 hover:text-white;
  @apply focus-visible:ring-4;
  @apply active:bg-red-800 active:text-white;
}

.btn-secondary {
  @apply bg-slate-200 border border-transparent ring-slate-300/25 text-black;
  @apply hover:bg-slate-300 hover:text-black;
  @apply focus-visible:ring-4;
  @apply active:bg-slate-400;
}

.btn-outline-secondary {
  @apply border border-white/50 ring-sky-600/50 text-white/50;
  @apply hover:bg-sky-600 hover:border-sky-600 hover:text-white;
  @apply focus-visible:ring-4;
  @apply active:bg-sky-700 active:border-sky-700 active:text-white;
}

.btn-sm {
  @apply px-3 py-2 text-sm leading-tight;
}

.btn-lg {
  @apply text-xl leading-tight;
}
