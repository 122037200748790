@import "~bootstrap/scss/type";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/utilities/clearfix";
@import "~bootstrap/scss/utilities/position";
@import "~bootstrap/scss/utilities/spacing";
@import "~bootstrap/scss/print";
@import "~bootstrap-datepicker/dist/css/bootstrap-datepicker3";

@each $color, $value in $theme-colors {
  @include bg-variant(".bg-#{$color}", $value, true);
}

.badge-warning {
  color: white !important;
}

.h1, .h2, .h3, .h4, .h5, .h6 {
  display: block;
}

.input-group-addon {
  @extend .input-group-prepend;
  @extend .input-group-text;
}

.modal {
  -webkit-overflow-scrolling: touch;
}

.tab-content > .active {
  display: block !important;
}

@for $i from 0 through 10 {
  .score-\[#{$i * 10}\] {
    background: mix($success, $warning, $i * 10);
  }
}

@for $i from 0 through 10 {
  .score-\[-#{$i * 10}\] {
    background: mix($danger, $warning, $i * 10);
  }
}

@each $bp in map-keys($grid-breakpoints) {
  .sr-only-#{$bp}-up {
    @include media-breakpoint-up($bp) {
      @include sr-only();
    }
  }
  .sr-only-#{$bp}-down {
    @include media-breakpoint-down($bp) {
      @include sr-only();
    }
  }
}

// Alignment

.text-justify  { text-align: justify !important; }
.text-wrap     { white-space: normal !important; }
.text-nowrap   { white-space: nowrap !important; }
.text-truncate { @include text-truncate(); }

// Responsive alignment

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .text#{$infix}-left   { text-align: left !important; }
    .text#{$infix}-right  { text-align: right !important; }
    .text#{$infix}-center { text-align: center !important; }
  }
}

// Transformation

.text-lowercase  { text-transform: lowercase !important; }
.text-uppercase  { text-transform: uppercase !important; }
.text-capitalize { text-transform: capitalize !important; }

// Weight and italics

.font-weight-light   { font-weight: $font-weight-light !important; }
.font-weight-lighter { font-weight: $font-weight-lighter !important; }
.font-weight-normal  { font-weight: $font-weight-normal !important; }
.font-weight-bold    { font-weight: $font-weight-bold !important; }
.font-weight-bolder  { font-weight: $font-weight-bolder !important; }
.font-italic         { font-style: italic !important; }

// Contextual colors

@each $color, $value in $theme-colors {
  @include text-emphasis-variant(".text-#{$color}", $value, true);
}

// Misc

.text-hide {
  @include text-hide($ignore-warning: true);
}

.text-decoration-none { text-decoration: none !important; }

.text-break {
  word-break: break-word !important; // Deprecated, but avoids issues with flex containers
  word-wrap: break-word !important; // Used instead of `overflow-wrap` for IE & Edge Legacy
}

// Reset

.text-reset { color: inherit !important; }
